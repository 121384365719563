
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/earlypay/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.exit-button-data-check {
  background: #b7aeae !important;
  border-color: #b7aeae !important;
  position: absolute;
  bottom: 0;
  z-index: 100;
  height: 45px;
  margin: 1rem 1.5rem 5rem;
  width: calc(100% - 3rem);
}


            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/earlypay/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.Select {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 270px;
  display: inline-block;
  margin-bottom: 25px;

  .label {
    position: absolute;
    top: -4px;
    left: 0;
    font-size: 12px;
    color: $c-dark;
    font-weight: 500;
    transform-origin: 0 0;
    transition: all 0.2s ease;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 135px;
    text-transform: uppercase;
  }

  .border {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background: var(--primary);
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: all 0.15s ease;
  }

  .error {
    color: $c-danger;
    font-size: 13px;
    padding: 3px 0;
    position: absolute;
    min-width: 280px;
  }

  select {
    width: 100%;
    font-family: var(--base-font-family) !important;
    font-weight: 500;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid $c-grey;
    /* styling */
    display: inline-block;
    line-height: 1.5em;
    padding: 18px 0 4px;
    background-color: transparent;

    /* reset */

    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px),
      calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;

    &:focus {
      outline: none;

      background-image: linear-gradient(45deg, var(--primary) 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, var(--primary) 50%),
        linear-gradient(to right, #ccc, #ccc);
      background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, calc(100% - 2.5em) 0.5em;
      background-size: 5px 5px, 5px 5px, 1px 1.5em;
      background-repeat: no-repeat;
      border-color: var(--primary);
      outline: 0;
    }

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }

    &:not(:placeholder-shown) {
      + span {
        transform: translateY(-18px) scale(0.75);
        color: $c-dark;
      }
    }
  }

  &.hasError {
    margin-bottom: 30px !important;

    select {
      border-bottom-color: $c-danger;
    }
  }
}

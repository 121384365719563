$c-primary: #0052d0;
$c-secandary: #0052d0;
$dots: false;
$version: 3.1;

$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;

@font-face {
  font-family: 'ProgramOT';
  src: url('#{$project-font-path}ProgramOT-Medium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ProgramOT';
  src: url('#{$project-font-path}ProgramOT-Book.otf');
  font-weight: 400;
  font-style: normal;
}

$base-font-family: 'ProgramOT', sans-serif;
$font-family-heading: 'ProgramOT', sans-serif;


            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/earlypay/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  padding-top: 60px;
  background: #fff;
  text-align: center;

  .content {
    border: 1px solid #e1e1e1;
    padding: 20px;
    border-radius: 8px;
    max-width: 280px;
    margin: 35px auto 0;

    .icon {
      width: 44px;
      height: 44px;
      margin: 15px auto 25px;
    }

    h4 {
      font-family: var(--base-font-family) !important;
      font-weight: 500;
    }

    p {
      span {
        color: $c-danger;
      }
    }
  }
}

@media (max-height: 600px) {
  .wrapper {
    padding-top: 30px;

    .content {
      margin: 15px auto 0;
    }
  }
}


            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/earlypay/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 15px;
  bottom: 0;
  left: 0;

  .buttons {
    margin-left: -7px;
    margin-right: -7px;
    display: flex;
    justify-content: space-between;

    button {
      width: 50%;
      margin: 0 7px;
      max-width: 250px;
    }
  }

  .btnRight {
    text-align: right;
  }

  .buttonsStacked {
    flex-flow: column wrap;
    align-items: center;
    button {
      &:first-child {
        margin-bottom: 2px;
      }
    }
  }
}

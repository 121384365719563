
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/earlypay/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  overflow-y: auto;
  button {
    opacity: 0;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }

  &.open {
    button {
      opacity: 1;
    }
  }

  .animTitle {
    text-align: center;
    margin-top: 1rem;
    font-size: 17px;
  }

  .content {
    margin: 15px 0 0;
    text-align: center;
    font-family: var(--heading-font-family) !important;
  }
}

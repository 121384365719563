
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/earlypay/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  img {
    margin-top: 25px;
    width: 100%;
    max-width: 270px;
  }
}
@media (max-height: 595px) {
  .wrapper {
    margin-top: -20px;
  }
}


            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/earlypay/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  font-size: 1rem;
  line-height: 1.375;
  letter-spacing: -0.01875rem; //-0.332143px;

  .content {
    margin-top: 1.5rem; //24
  }
  .heading {
    font-family: var(--heading-font-family) !important;
    color: var(--heading-text-color) !important;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
    line-height: 1.1;
    letter-spacing: -0.03125rem; //-0.5px
  }
  h3 {
    font-family: var(--heading-font-family) !important;
    color: var(--heading-text-color) !important;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
    line-height: 1.1;
    letter-spacing: -0.03125rem; //-0.5px
  }
  .subheading {
    font-family: var(--heading-font-family) !important;
    color: var(--heading-text-color) !important;
    font-weight: 400;
    font-size: 1.5rem !important; //24px
    line-height: 1.1;
    letter-spacing: -0.03125rem; //-0.5px
  }
  /*p,
    ul li {
        font-weight: 300;
    }*/

  ul {
    margin-bottom: 25px;
    margin-top: 20px !important;
  }
}

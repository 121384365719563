
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/earlypay/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.input {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 270px;
  display: inline-block;
  margin-bottom: 15px;

  .label {
    position: absolute;
    top: 16px;
    left: 0;
    font-family: var(--base-font-family) !important;
    color: $c-grey;
    font-weight: 500;
    transform-origin: 0 0;
    transition: all 0.2s ease;
    /*overflow: hidden;*/
    white-space: nowrap;
    /*text-overflow: ellipsis;*/
    max-width: 135px;
  }

  .border {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background: var(--primary);
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: all 0.15s ease;
  }

  .error {
    color: $c-danger;
    font-size: 13px;
    padding: 3px 0;
    position: absolute;
    min-width: 280px;
  }

  input,
  textarea {
    -webkit-appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 12px 0 4px;
    height: 46px;
    font-family: var(--base-font-family) !important;
    font-weight: 500;
    border-bottom: 2px solid $c-grey;
    background: none;
    border-radius: 0;
    transition: all 0.15s ease;

    &:not(:placeholder-shown) {
      + span {
        transform: translateY(-18px) scale(0.75);
        color: $c-dark;
      }
    }

    &:focus {
      background: none;
      outline: none;

      + span {
        color: $c-dark;
        transform: translateY(-18px) scale(0.75);

        + .border {
          transform: scaleX(1);
        }
      }
    }
  }

  textarea {
    resize: none;
    height: 60px;
    font-size: 14px;
    padding-top: 17px;
  }

  textarea + span + .border {
    bottom: 6px;
  }

  span {
    text-transform: uppercase;
    font-weight: 400;
  }

  &.hasError {
    margin-bottom: 30px !important;

    input,
    textarea {
      border-bottom-color: $c-danger;
    }
  }
}
